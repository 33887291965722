<template>

    <div style="position: sticky; top: 16px;">
        <b-card class="p-relative m-0" no-body>
            
            <b-dropdown
                size="sm"
                right
                no-caret   
                variant="transparent"
                style="position: absolute; right: 16px; top: 8px;"
                class="group-picker"
            >
                <template #button-content>
                     <feather-icon icon="MoreHorizontalIcon" class="text-muted" size="22" />
                </template>
                
                <b-dropdown-item>В работе</b-dropdown-item>
                <b-dropdown-item>В ремонте</b-dropdown-item>
                
<!--                <b-dropdown-item @click="refund">
                    Редактировать
                </b-dropdown-item>
                <b-dropdown-item @click="refund">
                    Удалить
                </b-dropdown-item>-->
            </b-dropdown>
            
            <b-card-body style="padding: 24px 24px 16px 24px">
                <p style="margin-bottom: 6px;"> Добыто чистыми <b-link @click="changeCurrency">в {{ ticker }}</b-link> </p>

                <div class="d-flex" style="padding-bottom: 6px;">
                    <p style="font-size: 28px; font-weight: 600;">
                        {{ (miner.current_balance * $blockchain.tickers.values[ticker].last).formatMoney(2, ' ', ' ') }} {{ User.getSymbol() }} 
                    </p>
                </div>
            
                <p style="margin-bottom: 6px; margin-top: 12px;" class="text-muted">Добыто всего </p>

                <div class="d-flex" style="padding-bottom: 6px;">
                    <p style="font-size: 28px; font-weight: 600;">
                        {{ (miner.total_balance * $blockchain.tickers.values[ticker].last).formatMoney(2, ' ', ' ') }} {{ User.getSymbol() }} 
                    </p>
                </div>

                <p class="text-muted" style="margin-bottom: 6px; margin-top: 12px; font-style: normal;">Мощность</p>

                <div class="d-flex" style="padding-bottom: 6px;">

                    <div class="energy-icon mt-auto mb-auto" style="margin-right: 6px;"></div>

                    <p class="mb-0">
                        <span  style="font-size: 24px; font-weight: 500;">{{ miner.machine.hashrate.formatMoney(0,' ', ' ') }}</span> 
                        <span style="font-size: 18px;"> TH/s</span>
                    </p>
                </div>
                
                
                <p class="text-muted font-weight-light" style="margin-bottom: 6px; margin-top: 12px;">Энергопотребление</p>

                <div class="d-flex" style="padding-bottom: 6px;">

                    <p class="mb-0">
                        <span  style="font-size: 24px; font-weight: 500;">{{ miner.machine.energy_usage.formatMoney(0,' ', ' ') }}</span> 
                        <span style="font-size: 15px;"> W</span>
                    </p>
                </div>
                
            </b-card-body>

            <hr class="m-0">

            <b-card-body style="padding: 16px 24px 12px 24px;">
                <div style="font-size: 14px; margin-bottom: 16px;">
                    
                    <div class="d-flex justify-content-between" style="margin: 8px 0;">
                        <span class="text-muted font-weight-light">
                            Контракт
                        </span>
                        <b-link :to="{ name: 'admin-contracts-view', params: { id: miner.target.guuid }}">
                           {{ miner.target.guuid }}
                        </b-link>
                    </div>
                    
                    <div class="d-flex justify-content-between" style="margin: 8px 0;">
                        <span class="text-muted font-weight-light">
                            Клиент
                        </span>
                        <b-link :to="{ name: 'admin-users-view', params: { id: miner.target.creator.id }}">
                          {{ miner.target.creator.firstName }} {{ miner.target.creator.lastName }}
                        </b-link>
                    </div>
                    
                    <div class="d-flex justify-content-between" style="margin: 8px 0;">
                        <span class="text-muted font-weight-light">
                            Инвестиции
                        </span>
                        <span>
                           {{ convertUSDtoBTC( miner.machine.price_amount ).toFixed(5) }} BTC
                        </span>
                    </div>

                    <div class="d-flex justify-content-between" style="margin: 8px 0;">
                        <span class="text-muted font-weight-light">
                            Дата начала
                        </span>
                        <span v-if="miner.status === 4">
                            -
                        </span>
                        <span v-else>
                            {{ toTimeString(miner.start_at) }}
                        </span>
                    </div>
                    
                    <div class="d-flex justify-content-between" style="margin: 8px 0;">
                        <span class="text-muted font-weight-light">
                            Дата окончания
                        </span>
                        <span v-if="miner.status === 4">
                            -
                        </span>
                        <span v-else>
                            {{ toTimeString(miner.end_at) }}
                        </span>
                    </div>

                </div>

            </b-card-body>
            
            <hr class="m-0">
            
            
            <b-card-body style="padding: 16px 24px 24px 24px;">
                <div style="font-size: 14px; margin-bottom: 16px;">
                    
                    <div v-if="isMinerEditState">
                        <span class="text-muted" style="font-size: 12px;">Серийный номер майнера</span>
                        <b-form-input placeholder="Серийный номер майнера" v-model="form.serial_number" size="sm" />

                        <span class="text-muted" style="font-size: 12px;">ЦОД идентификатор</span>
                        <b-form-input placeholder="ЦОД идентификатор" v-model="form.data_center_port" size="sm" />
                        
                        <span class="text-muted" style="font-size: 12px;">Погрешность хешрейта (%)</span>
                        <b-form-input placeholder="Погрешность хешрейта" v-model="form.hashrate_error" size="sm" />
                        
                        <span class="text-muted" style="font-size: 12px;">Погрешность энергопотребления (%)</span>
                        <b-form-input placeholder="Погрешность энергопотребления" v-model="form.energy_usage_error" size="sm" />
                        <b-button-group block class="mt-1">
                            <b-button @click="save" class="m-0" size="sm" block variant="outline-success"  >Сохранить</b-button>
                            <b-button @click="isMinerEditState = false" class="m-0" size="sm" block variant="outline-danger" >Отмена</b-button>
                        </b-button-group>
                    </div>
                    
                    <div v-else class="d-flex flex-column justify-content-between" style="margin: 8px 0;">
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="text-muted font-weight-light">Серийный номер</span>
                            <b-link @click="isMinerEditState = true">
                                <feather-icon icon="Edit2Icon" />
                            </b-link>
                        </div>
                        <span>
                           {{ miner.serial_number }} 
                        </span>
                        
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="text-muted font-weight-light">ЦОД идентификатор</span>
                        </div>
                        <span>
                           {{ miner.data_center_port }}
                        </span>

                        <div class="d-flex align-items-center justify-content-between">
                            <span class="text-muted font-weight-light">Погрешность хешрейта</span>
                        </div>
                        <span>
                           {{ miner.hashrate_error }} %
                        </span>

                        <div class="d-flex align-items-center justify-content-between">
                            <span class="text-muted font-weight-light">Погрешность энергопотребления</span>
                        </div>
                        <span>
                           {{ miner.energy_usage_error }} %
                        </span>
                    </div>
                </div>
            </b-card-body>
            
        </b-card>
        
        <b-button v-if="miner.status < 4" @click="isMinerDeleteState = true" class="mt-2" variant="danger" block pill >
            Архивировать
        </b-button>
        
        <miner-delete-modal 
            :contract="miner.target.id" 
            :miner="miner"
            :show="isMinerDeleteState"
            @onMinerDelete="$emit('onMinerDelete')" 
            @closed="isMinerDeleteState = false"
        />
        
    </div>
    
</template>

<script>

    import User from "@/modules/user/"
    import Blockchain from "@/modules/blockchain"
    import MinerDeleteModal from "@/views/Administrator/Contracts/modals/MinerDeleteModal"
     
    export default {

        data() {
            return {
                User,
                Blockchain,
                isMinerDeleteState: false,
                isMinerEditState: false,
                form: {
                    status: this.miner.status,
                    serial_number: this.miner.serial_number,
                    energy_usage_error: this.miner.energy_usage_error,
                    hashrate_error: this.miner.hashrate_error,
                    data_center_port: this.miner.data_center_port
                }
            }
        },
        props: {
            miner: {
                type: Object
            }
        },
        methods: {
            
            save( args ) {
              
               this.$request.post('contracts/updateMiner', {
                   status: this.form.status,
                   serial_number: this.form.serial_number,
                   miner: this.miner.id,
                   energy_usage_error: this.form.energy_usage_error,
                   hashrate_error: this.form.hashrate_error,
                   data_center_port: this.form.data_center_port
               }).then( rsp => {
                  this.miner.status = this.form.status;
                  this.miner.serial_number = this.form.serial_number;
                  this.miner.energy_usage_error = this.form.energy_usage_error;
                  this.miner.hashrate_error = this.form.hashrate_error;
                  this.miner.data_center_port =  this.form.data_center_port;
                  this.isMinerEditState = false;
               });
               
           },
            
            onMinerDelete( args ) {
                this.$router.replace({ 
                    name: 'admin-contracts-view', 
                    params: { 
                        id: this.miner.target.guuid 
                    }
                });
            },
            
            refund( args ) {
                this.$router.replace({
                    name: this.$route.name,
                    params: this.$route.params,
                    query: {
                        feedback: 1,
                        type: "miners",
                        action: "refund",
                        miner: this.miner.id
                    }
                });
            },
            
            tradein( args ) {
                this.$router.replace({
                    name: this.$route.name,
                    params: this.$route.params,
                    query: {
                        feedback: 1,
                        type: "miners",
                        action: "tradein",
                        miner: this.miner.id
                    }
                });
            },
            
            convertUSDtoBTC( value ) {
                return ( value / Blockchain.tickers.values['USD'].last );
            },
            
            getCurrentPercent() {
                return ((this.contract.total_balance * this.$blockchain.tickers.values['USD'].last ) / this.getPeriodProfitUSD()) * 100;     
            },
            changeCurrency( event ) {
                event.stopPropagation();
                this.$router.push(`${this.$route.path}?act=currency`);
            },
            getPeriodProfitUSD() {
                return (((84600 * 30 * this.contract.period * User.contracts.values.R * ( User.contracts.values.H * this.contract.ths )) / ( User.contracts.values.D * Math.pow(2, 32) )) * this.$blockchain.tickers.values["USD"].last) ;                
            },

            getPeriodProfit() {
                return (((84600 * 30 * this.contract.period * User.contracts.values.R * ( User.contracts.values.H * this.contract.ths )) / ( User.contracts.values.D * Math.pow(2, 32) )) * this.$blockchain.tickers.values[this.ticker].last) ;                
            },
            
            toTimeString( timestamp ) {
                
                let date = new Date(timestamp * 1000);
                return new Intl.DateTimeFormat('ru-RU', {
                    day: "2-digit",
                    month: '2-digit',
                    year: "numeric"
                }).format(date);
                
            }
            
        },
        computed: {
            ticker() {
                return User.currency;
            },
            
            user() {
                return User;
            }            
        },
        components: {
            MinerDeleteModal
        },
        watch: {

        },
        mounted() {

        }

    }

</script>