<template>
    <b-overlay
        :show="is_loading"
        blur="50"
        variant='transparent'
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
    >
        <b-card no-body>

            <b-card-header>
                <b-card-title>
                    Статистика майнера
                </b-card-title>
            </b-card-header>

            <b-card-body class="mb-0 pt-0 pb-0" style="padding: 16px; ">
                <b-row>
                    <b-col class="data-form">
                        <b-form-group>
                            <div class="d-flex justify-content-between">
                                <div style="flex-grow: 1">
                                    <!--<div class="text-muted" style="font-size: 12px;">Период</div>-->
                                    <date-range-picker
                                        ref="picker"
                                        :locale-data="calendar_locale"
                                        :max-date="maxPickerDate"
                                        :singleDatePicker="false"
                                        :timePicker="false"
                                        :timePicker24Hour="true"
                                        :showWeekNumbers="false"
                                        :showDropdowns="false"
                                        :autoApply="false"
                                        :linkedCalendars="false"
                                        :ranges="false"
                                        v-model="filters.period"
                                        @update="get"
                                    >
                                        <template v-slot:input="picker" style="min-width: 350px;">
                                            <span v-if="picker.startDate">{{ picker.startDate | date }} - {{ picker.endDate | date }}</span>
                                            <span v-else class="text-muted font-weight-normal">Период</span>
                                        </template>

                                    </date-range-picker>
                                </div>

                                <feather-icon icon="CalendarIcon" class="mt-auto mb-auto text-muted" size="18"  />

                            </div>
                        </b-form-group>
                    </b-col>


                </b-row>
            </b-card-body>

            <b-card-body class="data-form mb-0 pb-0" style="padding: 0 32px;">
                
                <b-row v-if="statistic" class="mb-2 filter-block">
                    
                    <b-col class="mt-auto mb-auto with-seperator" cols="4" style="border-right-width: 1px;">
                        <span class="text-muted">Добыто всего</span>
                        <p class="font-weight-bolder mb-0">
                            {{ getValueByTicker( statistic.summary.total_profit ) }} 
                            <span v-if="ticker !== 'BTC' ">{{ User.getSymbol() }}</span> 
                            <span v-else>BTC</span>
                        </p>
                    </b-col>
                    
                    <b-col class="mt-auto mb-auto with-seperator" cols="4" style="border-right-width: 1px;">
                        <span class="text-muted">Добыто чистыми</span>
                        <p class="font-weight-bolder mb-0">
                            {{ getValueByTicker( statistic.summary.current_profit ) }} 
                            <span v-if="ticker !== 'BTC' ">{{ User.getSymbol() }}</span> 
                            <span v-else>BTC</span>
                        </p>
                    </b-col>
                    
                    <b-col class="mt-auto mb-auto with-seperator" cols="4" style="border-right-width: 1px;">
                        <span class="text-muted">Добыто за 24 часа</span>
                        <p class="font-weight-bolder mb-0">
                            {{ getValueByTicker(  statistic.summary.daily_profit ) }} 
                            <span v-if="ticker !== 'BTC' ">{{ User.getSymbol() }}</span> 
                            <span v-else>BTC</span>
                        </p>
                    </b-col>
                
                </b-row>
                                
            </b-card-body>

            <b-card-body class="pt-0" style="padding: 15px 20px; ">
                <div style="font-size: 12px;">
                    <span class="text-muted" style='margin-right: 6px;'>Группировка</span>
                    <b-dropdown
                        variant="muted"
                        size="sm"
                        right
                        no-caret
                        class="group-picker"
                      >

                        <template #button-content>
                            <div class="d-flex">
                                <span class="text-primary mb-auto mb-auto font-weight-normal" style="font-size: 12px;">{{ filters.group.display }}</span>
                                <feather-icon icon="ChevronDownIcon" class="text-primary" style="margin: auto 2px;" />
                            </div>
                        </template>

                        <b-dropdown-item 
                            v-for="group in grouping" 
                            @click="filters.group = group"
                            class="text-black"
                       >
                            <div class="d-flex align-items-center">
                                <b-form-checkbox size="md" :checked="filters.group.value === group.value" /> 
                                <div class="mt-auto mb-auto">{{ group.display }}</div>
                            </div>
                        </b-dropdown-item>
                      
                    </b-dropdown>
                </div>
            </b-card-body>
            
            <div>

                 <b-row>

                    <b-col v-if="statistic">
                                                

                        <b-table-simple id="table-detalization" responsive >

                            <b-thead>
                                <b-tr>
                                    <b-th>Время</b-th>
                                    <b-th>Добыто всего</b-th>
                                    <b-th>Добыто чистыми</b-th>
                                    <b-th>Cервисный сбор</b-th>
                                    <b-th>Электроэнергия</b-th>
                                    <b-th>Сложность сети</b-th>
                                </b-tr>
                            </b-thead>

                            <b-tbody v-for="(section, sectionIndex) in statistic.sections" :key="sectionIndex" style="border-bottom: none; border-top: none; padding: 0; margin: 0;">

                                <b-tr style='border-top: none;' variant="gray">
                                  <b-th class="text-black" colspan="6">{{ section.name }}</b-th>
                                </b-tr>

                                <template v-for="(level,levelIndex) in section.levels">


                                    <b-tr v-for="(item, index) in level.items" :key="`${levelIndex}:${index}`">

                                        <b-th v-if="index === 0" class="text-black font-weight-bolder align-top text-nowrap" :rowspan="level.items.length" style="border-right: 1px solid #EBECF2;">
                                            <span> {{ level.name }} </span>
                                        </b-th>
                                        
                                        <b-th class="text-black font-weight-normal text-nowrap"> 
                                            {{ getEnergyByTicker(item.profit + item.energy) }} 
                                            <span v-if="ticker !== 'BTC' " class="text-muted">{{ User.getSymbol() }}</span> 
                                            <span v-else class="text-muted">BTC</span>
                                        </b-th>
                                        
                                        
                                        <b-th class="text-black font-weight-normal text-nowrap"> 
                                            {{ getValueByTicker(item.profit) }} 
                                            <span v-if="ticker !== 'BTC' " class="text-muted">{{ User.getSymbol() }}</span> 
                                            <span v-else class="text-muted">BTC</span>
                                        </b-th>
                                        
                                        <b-th class="text-black font-weight-normal text-nowrap"> 
                                            {{ getEnergyByTicker((item.profit + item.energy) * 0.05) }} 
                                            <span v-if="ticker !== 'BTC' " class="text-muted">{{ User.getSymbol() }}</span> 
                                            <span v-else class="text-muted">BTC</span>
                                        </b-th>
                                        
                                        <b-th class="text-black font-weight-normal text-nowrap"> 
                                            {{ getEnergyByTicker(item.energy) }} 
                                            <span v-if="ticker !== 'BTC' " class="text-muted">{{ User.getSymbol() }}</span> 
                                            <span v-else class="text-muted">BTC</span>
                                        </b-th>
                                        
                                        <b-th class="text-black font-weight-normal text-nowrap"> {{ item.difficult.formatMoney(0, "", " ") }} </b-th>

                                   </b-tr>



                                </template>


                            </b-tbody>

                        </b-table-simple>
                        
                        <template v-if="statistic.sections.length === 0">
                            <div class="d-flex flex-column justify-content-center" style="padding: 16px;">
                                <div class="nodata-icon ml-auto mr-auto"></div>
                                <p class="text-muted mt-1 ml-auto mr-auto text-center">
                                    Недостаточно данных для отображения статистики по контракту.
                                </p>
                            </div>
                        </template>

                        <b-card-body v-if="statistic.total_count > 0" class="p-0 d-flex">
                            <div class="ml-auto mr-auto">
                                 <b-pagination-nav :value="statistic.current_page" :link-gen="linkGen" :number-of-pages="statistic.last_page" use-router replace ></b-pagination-nav>
                            </div>
                        </b-card-body>

    <!--                    <b-table v-if="statistic.total_count > 0" responsive sticky-header :items="statistic.items" :fields="statisticTableFields" style="min-height: 400px">
                            <template #cell(difficult)="data">
                                <span class="text-muted">
                                    {{ data.value.formatMoney(0, "", " ") }}
                                </span>
                            </template>
                            <template #cell(profit)="data">
                              <p class="font-medium-2 mb-0 font-weight-bolder" v-b-tooltip.hover :title="`${data.value} BTC`">{{ getValueByTicker(data.value) }}</p>
                              <p class="font-small-2 text-muted  mb-0">{{ ticker }}</p>
                            </template>
                            <template #cell(ths)="data">
                                <p class="font-medium-2 mb-0 font-weight-bolder"> {{ data.value.formatMoney(0, "", " ") }} </p> 
                                <p class="font-small-2 text-muted  mb-0">TH/s</p>
                            </template>
                            <template #cell(timestamp)="data">
                                <div v-html="getStatisticTime(data.value)"></div>
                            </template>

                        </b-table>-->
                    </b-col>

                </b-row>

            </div>
        </b-card>
    </b-overlay>
        
</template>

<script>
    
    import User from "@/modules/user/"

    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

    export default {

        data() {
            return {
                
                User,
                
                is_loading: false,
                
                ticker: User.currency,
                
                maxPickerDate: new Date(),
                
                calendar_locale: {
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                    applyLabel: 'Применить',
                    cancelLabel: 'Отмена',
                    weekLabel: 'Н',
                    customRangeLabel: 'Период',
                    daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                    monthNames: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авн', 'Сен', 'Окт', 'Нов', 'Дек'],
                    firstDay: 0
                },
                
                filters: {
                    period:{
                        startDate: null,
                        endDate: null
                    },
                    group: {
                        value: "day",
                        display: "День"
                    }
                },
                
                statistic: null,
                
                grouping: [
                    { value: "day", display: "День" },
                    { value: "week", display: "Неделя" },
                    { value: "month", display: "Месяц" },
                    { value: "", display: "Весь контракт" },
                ],
                
            }
        },
        props: {
            miner: {
                type: Object
            }
        },
        methods: {
            
            getEnergyByTicker( value ) {
                
                if( this.ticker === "BTC" ) {
                    return value.toFixed(12);
                }
                
                return (value * this.$blockchain.tickers.values[User.currency].last).formatMoney(2, "", " ");
                
            },
            
            getValueByTicker( value ) {
                
                if( this.ticker === "BTC" ) {
                    return value.toFixed(12);
                }
                
                return (value * this.$blockchain.tickers.values[User.currency].last).formatMoney(2, "", " ");
                
            },
            
            setPeriod(  value ) {
                this.filter.period = value;
//                this.updateStatistic();
            },
            
            updateStatistic() {
                
                this.is_loading = true;
                
                this.$request.get('contracts.getStatistic', {
                    "for": this.miner.contract,
                    period: this.filter.period
                }).then( statistic => {
                    this.statistic = statistic;
                    this.is_loading = false;
                });
                
            },
            
            linkGen( pageNum ) {
              return `${this.$route.path}?page=${pageNum}`;                  
            },
            
            handleScroll( e ) {
                
                if(!this.statistic) return;
                if(!this.statistic.has_more_pages) return;
                if( this.is_loading ) return;
                
                let el = e.target;
               
                if( el.scrollTop + el.offsetHeight > ( el.scrollHeight - 255 ) ) {
                    
                  let params = this.params;
                  
                  params['page'] = this.statistic.current_page + 1;
                  
                  this.is_loading = true;
                  
                  this.$request.get("contracts/statistic", params ).then( result => {
                       this.statistic.has_more_pages = result.has_more_pages;
                       this.statistic.current_page = result.current_page;
                       this.statistic.sections.push( ...result.sections );
                       this.is_loading = false;                       
                  });
                  
                }
                
            },
            
            get() {
                this.$request.get('contracts/statistic', this.params ).then( statistic => {
                     this.statistic = statistic;
                });
            },
            
             formatDate(val) {
              return val ? new Intl.DateTimeFormat('ru', { day: 'numeric',  year: '2-digit', month: 'numeric' }).format(val) : ''
            }
        },
        
        filters: {
            date(val) {
              return val ? new Intl.DateTimeFormat('ru', { day: 'numeric',  year: '2-digit', month: 'numeric' }).format(val) : ''
            }
        },
        
        computed: {
            params() {
                
                let period = null;                
                
                if( this.filters.period.startDate && this.filters.period.endDate ) {
                    period = {};
                    period['start'] = this.filters.period.startDate  ;
                    period['end'] = this.filters.period.endDate  ;
                }
                
                let params = {
                    for: this.miner.contract,
                    miner: this.miner.id,
                    period: period,
                    group: this.filters.group.value,
                }
                
                
                if( this.$route.query.page ) {
                    params['page'] = this.$route.query.page;
                }
                
                return params;
            }
        },
        
        watch: {
            
            "filters.group" : function() {
                this.get();
            },
            
            "$route.query.page" : function( page ) {
                this.get();
                
            }
        },
        components: {
            DateRangePicker
        },
      
        mounted() {
            
            this.get();
            
        }

    }

</script>

<style lang="scss">
    

    .reportrange-text {
        background-color: transparent !important;
        border: none !important;
    }
    
    .vue-daterange-picker {
        flex-grow: 1;
        /*height: 28px !important;*/
    }
    
    [dir] .daterangepicker td.in-range {
        background-color: #E4EAFD !important;
    }
    
    .dark-layout .daterangepicker td.in-range {
        background-color: #262642 !important;
        color: #FFF;
    }
    
    [dir] .daterangepicker td.active, [dir] .daterangepicker td.active:hover {
        background-color: #426FF6 !important;
    }
    
    [dir=ltr] .daterangepicker td.start-date {
        border-radius: 22px 0 0 22px;
    }
    
    [dir=ltr] .daterangepicker td.end-date {
        border-radius: 0 22px 22px 0;
    }
    
    [dir] .daterangepicker {
        border: none;
        border-radius: 16px;
        box-shadow: 0px 6px 40px rgba(99, 109, 137, 0.15);
    }
    
    .reportrange-text {
        margin: 0 !important;
        padding: 0 !important;
    }
    
    [dir=ltr] .custom-checkbox.b-custom-control-lg .custom-control-label::before, [dir=ltr] .input-group-lg .custom-checkbox .custom-control-label::before {
        left: 0px;
    }
    
    [dir=ltr] .custom-checkbox.b-custom-control-lg .custom-control-label::after, [dir=ltr] .input-group-lg .custom-checkbox .custom-control-label::after {
        left: -2px;
    }
    
    .dark-layout .daterangepicker .drp-buttons {
            border-top: 1px solid rgba(255,255,255,0.1);
    }
    
    .dark-layout .daterangepicker .calendar-table .next span, .dark-layout .daterangepicker .calendar-table .prev span {
        border-color: #fff;    
    }
    
    
</style>