<template>
    <b-overlay
      :show="is_loading"
      spinner-variant="primary"
      variant='transparent'
      style="min-height: 50vh;"
    >
          
        <b-row v-if="miner">
            
            <b-col cols="12">
                <div class="d-flex" style="margin-bottom: 24px;">

                    <span style="font-size: 20px; font-weight: 600;">{{ miner.machine.name }}</span>

                    <div style="margin: auto 5px;">

                        <b-badge v-if="miner.status === 0" variant="danger">
                            Требуется оплата
                        </b-badge>
                        <b-badge v-else-if="miner.status === 1" class='badge-start-view' >
                            Старт {{ Utils.daysAgo( miner.start_at ) }}
                        </b-badge>
                        <b-badge v-else-if="miner.status === 2" >
                            Работает
                        </b-badge>
                         <b-badge v-else-if="miner.status === 3" variant="danger">
                            Обслуживание
                        </b-badge>
                        <b-badge v-else-if="miner.status === 4" variant="secondary">
                            Архивный
                        </b-badge>
                        <b-badge v-else-if="miner.status === 5" variant="danger">
                            Оплата получена
                        </b-badge>
                        <b-badge v-else-if="miner.status === 6" variant="secondary">
                            Приостановлен
                        </b-badge>
                    </div>


                </div>
            </b-col>
            
            <b-col cols="12">
                <b-alert 
                    show 
                    v-if="miner.archived"
                    variant="danger"
                >
                    <div class="alert-heading">
                        Майнер добавлен в архив
                    </div>
                    <div class="alert-body">
                        <p class="font-small-3 text-danger">Причина удаления: {{ miner.archived.type == 4 ?  miner.archived.enoch : type.name }}</p>
                        <p class="font-small-3 text-danger">Комментарий: {{ miner.archived.comment }}</p>
                        <span class="font-small-3">
                            Инициатор: <b-link :to="{ name: 'admin-users-view', params: { id: miner.archived.manager.id }}" class="text-danger">{{ miner.archived.manager.firstName }}</b-link>
                        </span>
                    </div>
                </b-alert>
            </b-col>
            
            <b-col md="3" lg="3" cols="12" class="mb-2">
                <card-view :miner="miner" />
            </b-col>
            <b-col md="9" lg="9" cols="12">
                                
                <b-row>
                    <b-col md="4" lg="4" cols="12">
                        <b-card no-body class="shadow">
                            <b-card-body style="padding: 16px;">
                                <div class="d-flex align-items-center">
                                    <div class="bg-light-success" style="width: 50px; height: 50px; line-height: 50px; text-align: center; border-radius: 12px;">
                                        <feather-icon icon="UserIcon" size="25" />
                                    </div>
                                    <div class="ml-1">
                                        <p class="m-0">
                                            <span class="font-weight-bolder">{{ toUSD(miner.current_balance).formatMoney(2," "," ") }} </span>
                                            <span class="text-muted">$</span>
                                        </p>
                                        <p class="m-0 text-muted font-small-3">Добыча пользователя</p>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col md="4" lg="4" cols="12">
                        <b-card no-body class="shadow">
                            <b-card-body style="padding: 16px;">
                                <div class="d-flex align-items-center">
                                    <div class="bg-light-primary" style="width: 50px; height: 50px; line-height: 50px; text-align: center; border-radius: 12px;">
                                        <feather-icon icon="PackageIcon" size="25" />
                                    </div>
                                    <div class="ml-1">
                                        <p class="m-0">
                                            <span class="font-weight-bolder">{{ toUSD(miner.total_balance - miner.current_balance).formatMoney(2," "," ") }} </span>
                                            <span class="text-muted">$</span>
                                        </p>
                                        <p class="m-0 text-muted font-small-3">Добыча платформы</p>
                                    </div>
                                </div>
                            </b-card-body>    
                        </b-card>
                    </b-col>
                    <b-col md="4" lg="4" cols="12">
                        <b-card no-body class="shadow">
                            <b-card-body style="padding: 16px;">
                                <div class="d-flex align-items-center">
                                    <div class="bg-light-warning" style="width: 50px; height: 50px; line-height: 50px; text-align: center; border-radius: 12px;">
                                        <feather-icon icon="StarIcon" size="25" />
                                    </div>
                                    <div class="ml-1">
                                        <div v-if="miner.guarantee == 4">
                                            <p class="m-0">
                                                <span class="font-weight-bolder">3 года </span>
                                                <span class="text-muted">(оборудование + TH/s)</span>
                                            </p>
                                        </div>
                                        <div v-else-if="miner.guarantee" class="d-flex flex-column justify-content-between">
                                            <p class="m-0">
                                                <span v-if="miner.guarantee == 1" class="font-weight-bolder">{{miner.guarantee}} год </span>
                                                <span v-else class="font-weight-bolder">{{miner.guarantee}} года </span>
                                                <span class="text-muted">(оборудование)</span>
                                            </p>
                                        </div>
                                        <p v-else class="m-0">
                                            -
                                        </p>
                                        <p class="m-0 text-muted font-small-3">Гарантия</p>
                                    </div>
                                </div>
                            </b-card-body>    
                        </b-card>
                    </b-col>
                </b-row>
                
                <statistic-view :miner="miner" />
                
            </b-col>
        </b-row>
        
    </b-overlay>
</template>

<script>

    import CardView from "./widgets/CardView"
    import StatisticView from "./widgets/StatisticView"
    import Utils from "@/modules/utils/"
    
    export default {

        data() {
            return {
                Utils,
                is_loading: true,
                miner: null,
                type: null,

                types: [
                    { name: 'Возврат майнера', value: 1 },
                    { name: 'Trade-In', value: 2 },
                    { name: 'Окончание периода обслуживания', value: 3 },
                    { name: 'Иное', value: 4 },
                ],
            }
        },
        methods: {
           
            
            toUSD( value ) {
                return ( value * this.$blockchain.tickers.values['USD'].last );
            },
            
            get() {
                this.$request.get("contracts/getMiner", {
                    contract: this.$route.params.id,
                    miner: this.$route.params.miner
                }).then( rsp => {
                    this.miner = rsp;
                    this.is_loading = false;
                    if (rsp.archived) this.type = this.types.find(item => item.value === rsp.archived.type );
                });
            }
        },
        components: {
            CardView,
            StatisticView
        },
        watch: {

        },
        mounted() {
            this.get();
        }

    }

</script>